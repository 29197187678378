import { useCallback, useEffect, useMemo, useState } from "react";
import Faq from "react-faq-component";
import styled from "styled-components";
import confetti from "canvas-confetti";
import * as anchor from "@project-serum/anchor";
import {
    Commitment,
    Connection,
    PublicKey,
    Transaction,
    LAMPORTS_PER_SOL
} from "@solana/web3.js";
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { GatewayProvider } from '@civic/solana-gateway-react';
import Countdown from "react-countdown";
import { Snackbar, Paper, LinearProgress, Chip } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { AlertState, getAtaForMint, toDate } from './utils';
import { CTAButton, MintButton } from './MintButton';
import Button from '@material-ui/core/Button';
import {
    awaitTransactionSignatureConfirmation,
    CANDY_MACHINE_PROGRAM,
    CandyMachineAccount,
    createAccountsForMint,
    getCandyMachineState,
    getCollectionPDA,
    mintOneToken,
    SetupState,
} from "./candy-machine";

const cluster = process.env.REACT_APP_SOLANA_NETWORK!.toString();
const decimals = process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS ? +process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS!.toString() : 9;
const splTokenName = process.env.REACT_APP_SPL_TOKEN_TO_MINT_NAME ? process.env.REACT_APP_SPL_TOKEN_TO_MINT_NAME.toString() : "TOKEN";

const WalletContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: right;
  Align-items: center;
  background-color: var(--nav-background-color);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 5%;
  padding-left: 5%;
`;

const WalletAmount = styled.div`
  color: black;
  width: auto;
  padding: 5px 5px 5px 16px;
  min-width: 48px;
  min-height: auto;
  border-radius: var(--buttons-border-radius);
  background-color: var(--main-text-color);
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1.75;
  text-transform: uppercase;
  border: 0;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: flex-start;
  gap: 10px;
`;

const Wallet = styled.ul`
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
`;

const ConnectButton = styled(WalletMultiButton)`
border-radius: var(--buttons-border-radius); !important;
padding: 6px 16px;
background-color: var(--buttons-background-color);
color: var(--buttons-text-color);
margin: 0 auto;
`;

const NFT = styled.div`
min-width: 400px;
//   margin: 0 auto;
padding: 20px;
//   flex: 1 0 auto;
`;

const Card = styled(Paper)`
display: inline-block;
background-color: var(--countdown-background-color) !important;
color: var(--countdown-text-color) !important;
margin: 5px;
width: 40px;
padding: 20px;
h1{
  margin:0px;
}
`;

const MintButtonContainer = styled.div`
margin: auto;
max-width: 400px;
padding: 20px 0px 20px 0px;
background-color: var(--mintinfo-background-color) !important;

  button.MuiButton-contained:not(.MuiButton-containedPrimary).Mui-disabled {
    color: #464646;
  }

  button.MuiButton-contained:not(.MuiButton-containedPrimary):hover,
  button.MuiButton-contained:not(.MuiButton-containedPrimary):focus {
    -webkit-animation: pulse 1s;
    animation: pulse 1s;
    box-shadow: 0 0 0 2em rgba(255, 255, 255, 0);
  }

  @-webkit-keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #ef8f6e;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #ef8f6e;
    }
  }
`;

const SolExplorerLink = styled.a`
  color: var(--title-text-color);
  border-bottom: 1px solid var(--title-text-color);
  font-weight: bold;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  outline: none;
  text-decoration: none;
  text-size-adjust: 100%;

  :hover {
    border-bottom: 2px solid var(--title-text-color);
  }
`;

const MainContainer = styled.div`
display: flex;
flex-direction: column;
margin: 7% 4%;
text-align: center;
justify-content: center;
`;

const MintContainer = styled(Paper)`
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
Align-items: flex-start;
margin: auto;
max-width: 65%;
min-width: 500px;
background-color: var(--mintbox-background-color) !important;
box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22) !important;
`;

const Price = styled(Chip)`
//   position: absolute;
margin: 10px 0;
color: var(--buttons-text-color) !important;
height: 22px !important;
border-radius: var(--buttons-border-radius) !important;

font-size: 0.8em !important;
background-color: var(--buttons-background-color) !important;
font-family: var(--font-family-primary) !important;
`;

const Image = styled.img`
  height: 400px;
  width: auto;
  border-radius: 7px;
  box-shadow: 5px 5px 40px 5px rgba(0, 0, 0, 0.5);
`;

const BorderLinearProgress = styled(LinearProgress)`
margin: 10px auto;
height: 8px !important;
border-radius: 30px;
//   border: 2px solid white;
box-shadow: 5px 5px 40px 5px rgba(0,0,0,0.5);
background-color:var(--progress-empty-color) !important;

  > div.MuiLinearProgress-barColorPrimary {
    background-color: var(--title-text-color) !important;
  }

  > div.MuiLinearProgress-bar1Determinate {
    border-radius: 30px !important;
    background-image: linear-gradient(270deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.5));
  }
`;

const DivBG1 = styled.div`
background: url("/img/background2d.png");  
background-repeat: no-repeat;
background-size: cover;
width: 100%;
`;

const DivBG2 = styled.div`
background: url("/img/background1.png");  
background-repeat: no-repeat;
background-size: cover;
width: 100%;
`;
const DivBG3 = styled.div`
background: url("/img/background1.png"); 
background-repeat: no-repeat;
background-size: cover;
width: 100%;
`;

const DivBG4 = styled.div`
background: url("/img/background3.png");  
background-repeat: no-repeat;
background-size: cover;
width: 100%;
`;

const Banner = styled.div`
width: 100%;
position: relative;
// min-height: 500px;
height: 50vh;
overflow: visible;
`;

const BannerSub = styled.div`
width: 100%;
`;

const Slider1 = styled.div`
background: url("/img/slider1c.png");  
background-size:auto 100%;
background-position:0 0;
animation:scrollGrid 159s linear infinite;
height: 133px;
margin-top: 150px;  
`;

const Slider2 = styled.div`
background: url("/img/slider2c.png");  
background-size:auto 100%;
background-position:0 0;
animation:scrollGrid 199s linear infinite;
height: 133px;
`;

const Tag = styled.div`
//   color: var(--title-text-color);
font-family: -apple-system, BlinkMacSystemFont, 
"Segoe UI", "Roboto", "Oxygen", 
"Ubuntu", "Cantarell", "Fira Sans", 
"Droid Sans", "Helvetica Neue", sans-serif;
color: var(--main-text-color);
opacity: .6;
font-size:14px;
font-weight:400;
line-height: 21px;
`;

const Status = styled.div`
font-size: 1.1rem;
font-weight: 100;
font-family: var(--font-family-secondary) !important;
`;

const Dot = styled.span`
height: 0.5rem;
width: 0.5rem;
background-color: #64ff71;
// background-color: #ffd600;
border-radius: 50%;
display: inline-block;
margin-right: 0.75rem;
box-shadow: rgb(14 226 63) 0px 0px 9.42713px 0.88543px;
// box-shadow: rgb(223 187 0) 0px 0px 9.42713px 0.88543px;

`;

const LinkButton = styled(Button)`
  display: block !important;
  margin: 0 auto !important;
  background-color: var(--buttons-background-color) !important;
  color: var(--buttons-text-color) !important;
  min-width: 300px !important;
  font-size: 1em !important;
`;


const data = {
    title: "",
    rows: [
        {
            title: "What is Spuds of Kulture?",
            content: "SOK is a limited PFP collection on the Solana Blockchain. It is a spin-off from an indie title in the works by <a href=\"https://twitter.com/Jaypordy\" target=\"_blank\" rel=\"noopener noreferrer\">@Jaypordy</a>."
        },
        {
            title: "How many Spuds are in the collection?",
            content: "1111 Kultured Spuds will be running free."
        },
        {
            title: "When is the public mint?",
            content: "Mint will take place on Jun 22 22 at 1200 CST (1800 UTC)."
        },
        {
            title: "How can I get a Kulture Token (WL)?",
            content: "Please visit our Twitter"
        },
        {
            title: "What is the mint price?",
            content: "0.35 - WL, 0.49 SOL - Public."
        },
        // {
        //     title: "Is there an utility/roadmap involved?",
        //     content: "Currently no. However Jaypordy's indie title is close to 18 months of development and at minimum all the spuds owners will be getting a digital copy of the game when it comes out."
        // },
        {
            title: "Why no Discord yet?",
            content: "Kulture is a global phenomenon, it cannot be contained to a mere discord group. Having said that, sheer demand for a kultured discord in the future won't fall into deaf ears either."
        },
        {
            title: "Where will SOK be listed?",
            content: " Magic Eden & Open Sea."
        },
        {
            title: "What is 'Kulture'?",
            content: "In case if you still have not figured it out, Kulture is a way of lyfe. Better embrace it."
        },
        {
            title: "Isn't Kultured spelled with a 'C'?",
            content: "No! Can you stop being such a boomer pls, k tnx."
        }]
}

const styles = {
    bgColor: 'transparent',
    // titleTextColor: 'var(--main-background-color)',
    // titleTextSize: '4.5rem',
    rowTitleColor: 'var(--sec-text-color)',
    // rowTitleTextSize: 'medium',
    rowContentColor: 'var(--main-text-color)',
    // rowContentTextSize: '16px',
    rowContentPaddingTop: '10px',
    rowContentPaddingBottom: '10px',
    // rowContentPaddingLeft: '50px',
    // rowContentPaddingRight: '150px',
    arrowColor: 'var(--sec-text-color)',
    //transitionDuration: "1s",
    // timingFunc: "ease"

};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};



const Logo = styled.div`
flex: 1 0 auto;

img {
height: 55px;
}
`;

const Menu = styled.ul`
list-style: none;
display: inline-flex;
Align-items: center;
margin-right: 10px;

li {
margin: 0 12px;

a {
  color: var(--main-text-color);
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  outline: none;
  text-decoration: none;
  text-size-adjust: 100%;
  touch-action: manipulation;
  transition: color 0.3s;
  padding-bottom: 15px;

  img {
    height: 40px;  
    max-height: 26px;
  }
}

a:hover, a:active {
  color: rgb(131, 146, 161);
  border-bottom: 4px solid var(--title-text-color);
}

img {
    height: 40px;  
    max-height: 30px;
  }

}
`;

const Partners = styled.ul`
list-style: none;
display: inline-flex;
Align-items: center;

li {
margin: 0 12px;

a {
  color: var(--main-text-color);
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  outline: none;
  text-decoration: none;
  text-size-adjust: 100%;
  touch-action: manipulation;
  transition: color 0.3s;
  padding-bottom: 15px;

  img {
    height: 150px;  
    max-height: 150px;
  }
}

a:hover, a:active {
  color: rgb(131, 146, 161);
  border-bottom: 4px solid var(--title-text-color);
}

img {
    height: 150px;  
    max-height: 150px;
  }

}
`;

const Footer2 = styled.ul`
flex: 1 0 auto;
list-style: none;
display: inline-flex;


li {
margin: 0 12px;

a {
  color: var(--main-text-color);
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  outline: none;
  text-decoration: none;
  text-size-adjust: 100%;
  touch-action: manipulation;
  transition: color 0.3s;
  padding-bottom: 15px;

  img {
    height: 40px;  
    max-height: 26px;
  }
}

a:hover, a:active {
  color: rgb(131, 146, 161);
  border-bottom: 4px solid var(--title-text-color);
}

img {
    height: 40px;  
    max-height: 30px;
  }

}
`;

const DivBGParent = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position:relative;
`;

const MintingInfo = styled.div`
max-width: 400px;
margin: auto;
`;
const RowSpaced = styled.div`
display: flex;
justify-content: space-between;
align-items: center;

`;
const RowRight = styled.div`
display: flex;
justify-content: right;
align-items: center;

`;
const RowCenter = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;

const Des = styled.div`
Align-items: flex-start;
max-width: 45%;
min-width: 400px;
text-align: left;
padding: 0 75px;
margin: 0 !important;
`;

const GoldTitle = styled.h2`
//   color: var(--title-text-color);
font-size: 4.5rem;
font-weight: 900;
font-family: var(--font-family-secondary) !important;
`;

const GoldTitle2 = styled.h2`
//   color: var(--title-text-color);
font-size: 5.0rem;
font-weight: 900;
font-family: var(--font-family-secondary) !important;
`;

const HLText = styled.span`
color: var(--main-text-color);

`;

const HLText2 = styled.span`
color: var(--title-text-color);

`;

const HighlightText = styled.div`
background-color:none !important;
text-align: center;
margin: 2% auto !important;
max-width: 50%;
font-family: var(--font-family-secondary) !important;
font-size: 1.25rem; 
`;
const HighlightText2 = styled.div`
//   background-color:var(--sec-background-color);
text-align: center;
max-width: 50%;
font-family: var(--font-family-secondary) !important;
font-size: 1.25rem; 
padding-bottom: 50px;
`;

const HighlightText3 = styled.div`
color: var(--title-text-color) !important;
background-color:var(--sec-background-color);
text-align: center !important;
max-width: 550px;
font-family: var(--font-family-secondary) !important;
//   font-weight:500;
font-size: 1.25rem; 
margin: 2% auto;
padding: 50px;
`;

const BannerImage = styled.img`
max-height: 750px;
min-height: 750px;
width: auto;
right: 0;
top: -200px;
display: block;
position: absolute;

`;

const Token = styled.img`
max-height: 22px;
margin-right:5px;
vertical-align: middle;
`;

const RoadmapImage = styled.img`
max-height: 600px;
min-height: 400px;
width: auto;
margin: 0 auto:
display: block;

`;

const Footer = styled.div`
color: var(--buttons-text-color);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  Align-items: center;
  background-color: var(--buttons-background-color);
  padding-top: 0px; 
  padding-bottom: 0px;
  padding-right: 20%;
  padding-left: 20%;
  font-family: var(--font-family-secondary) !important;
  font-size: 1.0em !important;
  font-weight: 500 !important;
`;


export interface HomeProps {
    candyMachineId?: anchor.web3.PublicKey;
    connection: anchor.web3.Connection;
    txTimeout: number;
    rpcHost: string;
    network: WalletAdapterNetwork;
}

const Home = (props: HomeProps) => {
    const [balance, setBalance] = useState<number>();
    const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
    const [isActive, setIsActive] = useState(false); // true when countdown completes or whitelisted
    const [solanaExplorerLink, setSolanaExplorerLink] = useState<string>("");
    const [itemsAvailable, setItemsAvailable] = useState(0);
    const [itemsRedeemed, setItemsRedeemed] = useState(0);
    const [itemsRemaining, setItemsRemaining] = useState(0);
    const [isSoldOut, setIsSoldOut] = useState(false);
    const [payWithSplToken, setPayWithSplToken] = useState(false);
    const [price, setPrice] = useState(0);
    const [priceLabel, setPriceLabel] = useState<string>("SOL");
    const [whitelistPrice, setWhitelistPrice] = useState(0);
    const [whitelistEnabled, setWhitelistEnabled] = useState(false);
    const [isBurnToken, setIsBurnToken] = useState(false);
    const [whitelistTokenBalance, setWhitelistTokenBalance] = useState(0);
    const [isEnded, setIsEnded] = useState(false);
    const [endDate, setEndDate] = useState<Date>();
    const [isPresale, setIsPresale] = useState(false);
    const [isWLOnly, setIsWLOnly] = useState(false);

    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: "",
        severity: undefined,
    });

    const [needTxnSplit, setNeedTxnSplit] = useState(true);
    const [setupTxn, setSetupTxn] = useState<SetupState>();

    const wallet = useWallet();
    const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();

    const rpcUrl = props.rpcHost;
    const solFeesEstimation = 0.012; // approx of account creation fees

    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet]);

    const refreshCandyMachineState = useCallback(
        async (commitment: Commitment = 'confirmed') => {
            if (!anchorWallet) {
                return;
            }

            const connection = new Connection(props.rpcHost, commitment);

            if (props.candyMachineId) {
                try {
                    const cndy = await getCandyMachineState(
                        anchorWallet,
                        props.candyMachineId,
                        connection,
                    );

                    setCandyMachine(cndy);
                    setItemsAvailable(cndy.state.itemsAvailable);
                    setItemsRemaining(cndy.state.itemsRemaining);
                    setItemsRedeemed(cndy.state.itemsRedeemed);

                    var divider = 1;
                    if (decimals) {
                        divider = +('1' + new Array(decimals).join('0').slice() + '0');
                    }

                    // detect if using spl-token to mint
                    if (cndy.state.tokenMint) {
                        setPayWithSplToken(true);
                        // Customize your SPL-TOKEN Label HERE
                        // TODO: get spl-token metadata name
                        setPriceLabel(splTokenName);
                        setPrice(cndy.state.price.toNumber() / divider);
                        setWhitelistPrice(cndy.state.price.toNumber() / divider);
                    } else {
                        setPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
                        setWhitelistPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
                    }


                    // fetch whitelist token balance
                    if (cndy.state.whitelistMintSettings) {
                        setWhitelistEnabled(true);
                        setIsBurnToken(cndy.state.whitelistMintSettings.mode.burnEveryTime);
                        setIsPresale(cndy.state.whitelistMintSettings.presale);
                        setIsWLOnly(!isPresale && cndy.state.whitelistMintSettings.discountPrice === null);

                        if (cndy.state.whitelistMintSettings.discountPrice !== null && cndy.state.whitelistMintSettings.discountPrice !== cndy.state.price) {
                            if (cndy.state.tokenMint) {
                                setWhitelistPrice(cndy.state.whitelistMintSettings.discountPrice?.toNumber() / divider);
                            } else {
                                setWhitelistPrice(cndy.state.whitelistMintSettings.discountPrice?.toNumber() / LAMPORTS_PER_SOL);
                            }
                        }

                        let balance = 0;
                        try {
                            const tokenBalance =
                                await props.connection.getTokenAccountBalance(
                                    (
                                        await getAtaForMint(
                                            cndy.state.whitelistMintSettings.mint,
                                            anchorWallet.publicKey,
                                        )
                                    )[0],
                                );

                            balance = tokenBalance?.value?.uiAmount || 0;
                        } catch (e) {
                            console.error(e);
                            balance = 0;
                        }
                        if (commitment !== "processed") {
                            setWhitelistTokenBalance(balance);
                        }
                        setIsActive(isPresale && !isEnded && balance > 0);

                    } else {
                        setWhitelistEnabled(false);
                    }

                    // end the mint when date is reached
                    if (cndy?.state.endSettings?.endSettingType.date) {
                        setEndDate(toDate(cndy.state.endSettings.number));
                        if (
                            cndy.state.endSettings.number.toNumber() <
                            new Date().getTime() / 1000
                        ) {
                            setIsEnded(true);
                            setIsActive(false);
                        }
                    }
                    // end the mint when amount is reached
                    if (cndy?.state.endSettings?.endSettingType.amount) {
                        let limit = Math.min(
                            cndy.state.endSettings.number.toNumber(),
                            cndy.state.itemsAvailable,
                        );
                        setItemsAvailable(limit);
                        if (cndy.state.itemsRedeemed < limit) {
                            setItemsRemaining(limit - cndy.state.itemsRedeemed);
                        } else {
                            setItemsRemaining(0);
                            cndy.state.isSoldOut = true;
                            setIsEnded(true);
                        }
                    } else {
                        setItemsRemaining(cndy.state.itemsRemaining);
                    }

                    if (cndy.state.isSoldOut) {
                        setIsActive(false);
                    }

                    const [collectionPDA] = await getCollectionPDA(props.candyMachineId);
                    const collectionPDAAccount = await connection.getAccountInfo(
                        collectionPDA,
                    );

                    const txnEstimate =
                        892 +
                        (!!collectionPDAAccount && cndy.state.retainAuthority ? 182 : 0) +
                        (cndy.state.tokenMint ? 66 : 0) +
                        (cndy.state.whitelistMintSettings ? 34 : 0) +
                        (cndy.state.whitelistMintSettings?.mode?.burnEveryTime ? 34 : 0) +
                        (cndy.state.gatekeeper ? 33 : 0) +
                        (cndy.state.gatekeeper?.expireOnUse ? 66 : 0);

                    setNeedTxnSplit(txnEstimate > 1230);
                } catch (e) {
                    if (e instanceof Error) {
                        if (
                            e.message === `Account does not exist ${props.candyMachineId}`
                        ) {
                            setAlertState({
                                open: true,
                                message: `Couldn't fetch candy machine state from candy machine with address: ${props.candyMachineId}, using rpc: ${props.rpcHost}! You probably typed the REACT_APP_CANDY_MACHINE_ID value in wrong in your .env file, or you are using the wrong RPC!`,
                                severity: 'error',
                                hideDuration: null,
                            });
                        } else if (
                            e.message.startsWith('failed to get info about account')
                        ) {
                            setAlertState({
                                open: true,
                                message: `Couldn't fetch candy machine state with rpc: ${props.rpcHost}! This probably means you have an issue with the REACT_APP_SOLANA_RPC_HOST value in your .env file, or you are not using a custom RPC!`,
                                severity: 'error',
                                hideDuration: null,
                            });
                        }
                    } else {
                        setAlertState({
                            open: true,
                            message: `${e}`,
                            severity: 'error',
                            hideDuration: null,
                        });
                    }
                    console.log(e);
                }
            } else {
                setAlertState({
                    open: true,
                    message: `Your REACT_APP_CANDY_MACHINE_ID value in the .env file doesn't look right! Make sure you enter it in as plain base-58 address!`,
                    severity: 'error',
                    hideDuration: null,
                });
            }
        },
        [anchorWallet, props.candyMachineId, props.rpcHost, isEnded, isPresale, props.connection],
    );

    const renderGoLiveDateCounter = ({ days, hours, minutes, seconds }: any) => {
        return (
            <div><Card elevation={1}><h1>{days}</h1>Days</Card><Card elevation={1}><h1>{hours}</h1>
                Hours</Card><Card elevation={1}><h1>{minutes}</h1>Mins</Card><Card elevation={1}>
                    <h1>{seconds}</h1>Secs</Card></div>
        );
    };

    const renderEndDateCounter = ({ days, hours, minutes }: any) => {
        let label = "";
        if (days > 0) {
            label += days + " days "
        }
        if (hours > 0) {
            label += hours + " hours "
        }
        label += (minutes + 1) + " minutes left to MINT."
        return (
            <div><h3>{label}</h3></div>
        );
    };

    function displaySuccess(mintPublicKey: any, qty: number = 1): void {
        let remaining = itemsRemaining - qty;
        setItemsRemaining(remaining);
        setIsSoldOut(remaining === 0);
        if (isBurnToken && whitelistTokenBalance && whitelistTokenBalance > 0) {
            let balance = whitelistTokenBalance - qty;
            setWhitelistTokenBalance(balance);
            setIsActive(isPresale && !isEnded && balance > 0);
        }
        setSetupTxn(undefined);
        setItemsRedeemed(itemsRedeemed + qty);
        if (!payWithSplToken && balance && balance > 0) {
            setBalance(balance - ((whitelistEnabled ? whitelistPrice : price) * qty) - solFeesEstimation);
        }
        setSolanaExplorerLink(cluster === "devnet" || cluster === "testnet"
            ? ("https://solscan.io/token/" + mintPublicKey + "?cluster=" + cluster)
            : ("https://solscan.io/token/" + mintPublicKey));
        setIsMinting(false);
        throwConfetti();
    };

    function throwConfetti(): void {
        confetti({
            particleCount: 400,
            spread: 70,
            origin: { y: 0.6 },
        });
    }

    const onMint = async (
        beforeTransactions: Transaction[] = [],
        afterTransactions: Transaction[] = [],
    ) => {
        try {
            if (wallet.connected && candyMachine?.program && wallet.publicKey) {
                setIsMinting(true);
                let setupMint: SetupState | undefined;
                if (needTxnSplit && setupTxn === undefined) {
                    setAlertState({
                        open: true,
                        message: 'Please validate account setup transaction',
                        severity: 'info',
                    });
                    setupMint = await createAccountsForMint(
                        candyMachine,
                        wallet.publicKey,
                    );
                    let status: any = { err: true };
                    if (setupMint.transaction) {
                        status = await awaitTransactionSignatureConfirmation(
                            setupMint.transaction,
                            props.txTimeout,
                            props.connection,
                            true,
                        );
                    }
                    if (status && !status.err) {
                        setSetupTxn(setupMint);
                        setAlertState({
                            open: true,
                            message:
                                'Setup transaction succeeded! You can now validate mint transaction',
                            severity: 'info',
                        });
                    } else {
                        setAlertState({
                            open: true,
                            message: 'Mint failed! Please try again!',
                            severity: 'error',
                        });
                        return;
                    }
                }

                const setupState = setupMint ?? setupTxn;
                const mint = setupState?.mint ?? anchor.web3.Keypair.generate();
                let mintResult = await mintOneToken(
                    candyMachine,
                    wallet.publicKey,
                    mint,
                    beforeTransactions,
                    afterTransactions,
                    setupState,
                );

                let status: any = { err: true };
                let metadataStatus = null;
                if (mintResult) {
                    status = await awaitTransactionSignatureConfirmation(
                        mintResult.mintTxId,
                        props.txTimeout,
                        props.connection,
                        true,
                    );

                    metadataStatus =
                        await candyMachine.program.provider.connection.getAccountInfo(
                            mintResult.metadataKey,
                            'processed',
                        );
                    console.log('Metadata status: ', !!metadataStatus);
                }

                if (status && !status.err && metadataStatus) {
                    setAlertState({
                        open: true,
                        message: 'Congratulations! Mint succeeded!',
                        severity: 'success',
                    });

                    // update front-end amounts
                    displaySuccess(mint.publicKey);
                    refreshCandyMachineState('processed');
                } else if (status && !status.err) {
                    setAlertState({
                        open: true,
                        message:
                            'Mint likely failed! Anti-bot SOL 0.01 fee potentially charged! Check the explorer to confirm the mint failed and if so, make sure you are eligible to mint before trying again.',
                        severity: 'error',
                        hideDuration: 8000,
                    });
                    refreshCandyMachineState();
                } else {
                    setAlertState({
                        open: true,
                        message: 'Mint failed! Please try again!',
                        severity: 'error',
                    });
                    refreshCandyMachineState();
                }
            }
        } catch (error: any) {
            let message = error.msg || 'Minting failed! Please try again!';
            if (!error.msg) {
                if (!error.message) {
                    message = 'Transaction Timeout! Please try again.';
                } else if (error.message.indexOf('0x138')) {
                } else if (error.message.indexOf('0x137')) {
                    message = `SOLD OUT!`;
                } else if (error.message.indexOf('0x135')) {
                    message = `Insufficient funds to mint. Please fund your wallet.`;
                }
            } else {
                if (error.code === 311) {
                    message = `SOLD OUT!`;
                } else if (error.code === 312) {
                    message = `Minting period hasn't started yet.`;
                }
            }

            setAlertState({
                open: true,
                message,
                severity: "error",
            });
        } finally {
            setIsMinting(false);
        }
    };

    useEffect(() => {
        (async () => {
            if (anchorWallet) {
                const balance = await props.connection.getBalance(anchorWallet!.publicKey);
                setBalance(balance / LAMPORTS_PER_SOL);
            }
        })();
    }, [anchorWallet, props.connection]);

    useEffect(() => {
        refreshCandyMachineState();
    }, [
        anchorWallet,
        props.candyMachineId,
        props.connection,
        isEnded,
        isPresale,
        refreshCandyMachineState
    ]);

    return (
        <main>
            <WalletContainer>
                <Logo><a href="https://www.spudsofkulture.com/" target="_self" rel="noopener noreferrer"><img alt=""
                    src="logo.png" /></a></Logo>
                <Menu>
                    <li><Status>
                        <Dot></Dot>
                        <span >Current Status: </span>Mint is LIVE 
                        {/* <span >Current Status: </span>WL Open  */}
                    </Status>
                    </li>
                    <li> | 
                    </li>
                    <li><a href="#about" target="_self"
                        rel="noopener noreferrer">About</a>
                    </li>
                    <li><a href="#roadmap" target="_self"
                        rel="noopener noreferrer">Roadmap</a>
                    </li>
                    <li><a href="#faq" target="_self"
                        rel="noopener noreferrer">FAQ</a>
                    </li>

                    <li><a href="https://twitter.com/spudsofkulture" target="_blank" rel="noopener noreferrer"><img alt=""
                        src="/img/social-twitter.png" /></a>
                    </li>
                    <li><a href="https://www.youtube.com/user/GaxelGaming" target="_blank" rel="noopener noreferrer"><img alt=""
                        src="/img/social-youtube.png" /></a>
                    </li>
                    <li><a href="https://jaypordy.itch.io/wannabe-the-cowboy" target="_blank" rel="noopener noreferrer"><img alt=""
                        src="/img/social-link.png" /></a>
                    </li>
                    <li><a href="https://magiceden.io/drops/spuds_of_kulture" target="_blank" rel="noopener noreferrer"><img alt=""
                        src="/img/ME.png" /></a>
                    </li>
                </Menu>
                <Wallet>
                    {wallet ?
                        <WalletAmount>{(balance || 0).toLocaleString()} SOL<ConnectButton /></WalletAmount> :
                        <ConnectButton>Connect Wallet</ConnectButton>}
                </Wallet>
            </WalletContainer>

            <DivBGParent><DivBG1>
                <MainContainer>
                    <MintContainer elevation={3}>
                        <NFT>
                            <br />
                            <div><Image src="/img/sok.gif"
                                alt="Spuds of Culture Mint" /></div>
                            <MintingInfo>
                                <RowRight><Price label={isActive && whitelistEnabled && (whitelistTokenBalance > 0) ? (whitelistPrice + " " + priceLabel) : (price + " " + priceLabel)} /></RowRight>
                                <div>
                                {wallet && isActive && whitelistEnabled && (whitelistTokenBalance > 0) && isBurnToken &&
                                    <h4>You own {whitelistTokenBalance} <a href="https://solscan.io/token/ToPGAbmu9c47RhsK7HneUSAPio8uAKR9MGuQbcV2yW7" target="_blank" rel="noopener noreferrer"><Token src="/img/coin.png" alt="kulture coin" /></a>
                                         $Kulture {whitelistTokenBalance > 1 ? "tokens" : "token"}.</h4>}
                                {wallet && isActive && whitelistEnabled && (whitelistTokenBalance > 0) && !isBurnToken &&
                                    <h4>You are whitelisted and allowed to mint.</h4>} </div>
                                {wallet && isActive && endDate && Date.now() < endDate.getTime() &&
                                    <Countdown
                                        date={toDate(candyMachine?.state?.endSettings?.number)}
                                        onMount={({ completed }) => completed && setIsEnded(true)}
                                        onComplete={() => {
                                            setIsEnded(true);
                                        }}
                                        renderer={renderEndDateCounter}
                                    />}
                                {wallet && isActive && <BorderLinearProgress variant="determinate"
                                    value={101 - (itemsRemaining * 100 / itemsAvailable)} />} 
                                {wallet && isActive &&
                                    <Tag><RowSpaced><div>Total minted : </div><div><b>{Math.round(((((itemsRedeemed +3 ) / itemsAvailable) * 100)+ Number.EPSILON) *100)/100}% </b> ({itemsRedeemed +3 }/{itemsAvailable})</div></RowSpaced></Tag>}

                            </MintingInfo>
                            <br />
                            <MintButtonContainer>
                                {!isActive && !isEnded && candyMachine?.state.goLiveDate && (!isWLOnly || whitelistTokenBalance > 0) ? (
                                    <Countdown
                                        date={toDate(candyMachine?.state.goLiveDate)}
                                        onMount={({ completed }) => completed && setIsActive(!isEnded)}
                                        onComplete={() => {
                                            setIsActive(!isEnded);
                                        }}
                                        renderer={renderGoLiveDateCounter}
                                    />) : (
                                    !wallet ? (
                                        <ConnectButton>Connect Wallet</ConnectButton>
                                    ) : (!isWLOnly || whitelistTokenBalance > 0) ?
                                        candyMachine?.state.gatekeeper &&
                                            wallet.publicKey &&
                                            wallet.signTransaction ? (
                                            <GatewayProvider
                                                wallet={{
                                                    publicKey:
                                                        wallet.publicKey ||
                                                        new PublicKey(CANDY_MACHINE_PROGRAM),
                                                    //@ts-ignore
                                                    signTransaction: wallet.signTransaction,
                                                }}
                                                // // Replace with following when added
                                                // gatekeeperNetwork={candyMachine.state.gatekeeper_network}
                                                gatekeeperNetwork={
                                                    candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                                                } // This is the ignite (captcha) network
                                                /// Don't need this for mainnet
                                                clusterUrl={rpcUrl}
                                                cluster={cluster}
                                                options={{ autoShowModal: false }}
                                            >
                                                <MintButton
                                                    candyMachine={candyMachine}
                                                    isMinting={isMinting}
                                                    isActive={isActive}
                                                    isEnded={isEnded}
                                                    isSoldOut={isSoldOut}
                                                    onMint={onMint}
                                                />
                                            </GatewayProvider>
                                        ) : (
                                            <MintButton
                                                candyMachine={candyMachine}
                                                isMinting={isMinting}
                                                isActive={isActive}
                                                isEnded={isEnded}
                                                isSoldOut={isSoldOut}
                                                onMint={onMint}
                                            />

                                        ) :
                                        <h1>Mint is private.</h1>
                                )}
                            </MintButtonContainer>
                            <br />
                            {wallet && isActive && solanaExplorerLink &&
                                <SolExplorerLink href={solanaExplorerLink} target="_blank">View on
                                    Solscan</SolExplorerLink>}
                        </NFT>
                        <Des>
                            <GoldTitle><em>SPUDS OF <HLText2>KULTURE</HLText2></em></GoldTitle>
                            <p><HLText>Spuds of Kulture (SOK)</HLText> is a
                                limited PFP collection of 1111 unique digital collectibles alive on the Solana Blockchain. It is a spin-off from an<a href="https://jaypordy.itch.io/wannabe-the-cowboy" target="_blank" rel="noopener noreferrer">indie title</a> in the works by
                                <a href="https://twitter.com/Jaypordy" target="_blank" rel="noopener noreferrer">@Jaypordy</a>.</p>
                            <p>Be an owner of a unique Spud during the public mint sale! Own a Spud, Show it to your crush, Ask them out..PROFIT!!</p>
                        </Des>
                    </MintContainer>
                </MainContainer>
            </DivBG1></DivBGParent>
            <Snackbar
                open={alertState.open}
                autoHideDuration={6000}
                onClose={() => setAlertState({ ...alertState, open: false })}
            >
                <Alert
                    onClose={() => setAlertState({ ...alertState, open: false })}
                    severity={alertState.severity}
                >
                    {alertState.message}
                </Alert>
            </Snackbar>

            <DivBGParent id="about">
                <DivBG2>
                    <RowCenter>
                        <HighlightText>
                            <GoldTitle2><em>EMBRACE THE <HLText2>KULTURE</HLText2> SHIFT!</em></GoldTitle2>
                            <p>Many claim that they're a club, some call themselves a cult, I mean a tribe, some want to be in a gang or whatever. But very few understand <HLText>#Kulture.</HLText></p>
                            <p>Being a Spud is a <HLText>#lyfestyle</HLText> choice. You live it or you don't, there's simply no two ways about it! A bunch of kultured and very  sophisticated potatoes.. It's just how it is.</p>
                            <p>Now the real question is, <HLText><em>Are you Kultured?</em></HLText></p>
                        </HighlightText>
                    </RowCenter>
                    <Banner >

                        <BannerSub><BannerImage src="/img/banner.png"
                            alt="spuds of kulture showcase" /> </BannerSub>
                        <Slider1></Slider1>
                        <Slider2></Slider2>

                    </Banner>
                </DivBG2>
            </DivBGParent>
            <DivBGParent><DivBG3 id="roadmap"><RowCenter>
                <HighlightText2>
                    <GoldTitle2><em>UMMM.. <HLText2>ROADMAP</HLText2> YOU SAY?</em></GoldTitle2>
                    <p>We just had a crazy party last night and one not-so-kultured idiot set the board room on fire! This is what we could find from the few notes left. Make it what you will.</p>
                    <RoadmapImage src="/img/roadmap.png" alt="spuds of kulture showcase" />
                    <p>In all seriousness, unlike most of the projects that promise heaven and earth and deliver nothing we like to keep it real with our Kultured Kommunity.</p> 
                    <p>While SOK being predominantly a PFP project, <a href="https://jaypordy.itch.io/wannabe-the-cowboy" target="_blank" rel="noopener noreferrer">Jaypordy's indie title</a> is close to 18 months of development and at minimum all the spuds owners will be getting a digital copy of the game when it comes out. 
                        Additionally we also have Kool Kultured merch ideas for SOK. You will not be dissapointed!</p>
                        <a href="https://jaypordy.itch.io/wannabe-the-cowboy" target="_blank" rel="noopener noreferrer"><LinkButton>Visit Game Page</LinkButton></a>
                </HighlightText2>
            </RowCenter></DivBG3></DivBGParent>

            <DivBGParent><DivBG4 id="faq"><RowCenter>
                <HighlightText3>
                    <GoldTitle2><em>GET <HLText2>KULTURED!</HLText2></em></GoldTitle2>
                    <Faq data={data}
                        styles={styles}
                        config={config}
                    />
                </HighlightText3>
            </RowCenter></DivBG4></DivBGParent>

            <Footer>
                {/* <Footer2>
                    <li>Find us on
                    </li>
                    <li><a href="https://twitter.com/Jaypordy" target="_blank" rel="noopener noreferrer"><img alt=""
                        src="/img/social-twitter.png" /></a>
                    </li>
                </Footer2> */}
                <Footer2>
                    <li>SPUDS OF KULTURE, 2022
                    </li>
                    <li><img alt="spuds of kulture logo" src="/favicon.ico" />
                    </li>
                </Footer2>
                <br />
                <Partners>
                    As seen on:
                    <li><a href="https://magiceden.io/drops/spuds_of_kulture" target="_blank" rel="noopener noreferrer"><img alt="magic eden"
                        src="/img/me-logo.png" /></a>
                    </li>
                    <li><a href="https://nftcalendar.io/event/spuds-of-kulture/" target="_blank" rel="noopener noreferrer"><img alt=""
                        src="/img/nft-cal-logo.svg" /></a>
                    </li>
                </Partners>
            </Footer>

        </main>
    );
};

export default Home;
